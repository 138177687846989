import React, { useState, createContext, useContext } from 'react';
import { node } from 'prop-types';
import useGuestInfo from '@/usecase/use-guest-info';
import useQueryString from '@/hooks/useQueryString';
import { getItem, setItem } from '@helpers/localstorage';
import useGroupLink from '@hooks/useGroupLink';

// initialization guest context value
const GuestContext = createContext({});

/**
 * @function AuthProvider Custom provider to store guest information
 * @returns {JSX.Element}
 */
function GuestProvider({ children }) {
  const { getQueryValue } = useQueryString();
  const isGroup = useGroupLink();
  const [currentCode, setCurrentCode] = useState(
    getQueryValue('code') || (isGroup && getItem('guestCode')) || '',
  );

  const { isRegistered, isLoading, ...guestInfo } = useGuestInfo(currentCode);

  const updateGuest = (guestCode, name) => {
    setItem('guestCode', guestCode);
    setItem('guestName', name);
    setCurrentCode(guestCode);
  };

  return (
    <GuestContext.Provider
      value={{
        guest: guestInfo,
        onUpdateGuest: updateGuest,
        isLoading: isLoading,
        isRegistered: isRegistered,
      }}
    >
      {children}
    </GuestContext.Provider>
  );
}

/**
 * Custom hook to return
 * @returns {GuestType}
 */
const useGuest = () => {
  const context = useContext(GuestContext);

  if (typeof context === 'undefined') {
    throw new Error('useGuest() should be used inside <GuestContext />');
  }

  return context;
};

GuestProvider.propTypes = {
  children: node.isRequired,
};

export { GuestProvider, useGuest };
