export const BG_COVER = `https://ik.imagekit.io/farahoktarina/yerryyohanaririassa/FOTO%20COVER%20PORTRAIT.jpg?updatedAt=1705986997642`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/farahoktarina/yerryyohanaririassa/desktop.jpg?updatedAt=1705982480491`;

export const BG_COVER_SLIDESHOW_1 = `https://ik.imagekit.io/farahoktarina/yerryyohanaririassa/FOTO%20COVER%20DALAM%20PORTRAIT%20(SLIDESHOW).jpg?updatedAt=1705981144985`;
export const BG_COVER_SLIDESHOW_2 = `https://ik.imagekit.io/farahoktarina/yerryyohanaririassa/option%20photo%201.jpg?updatedAt=1705981144515`;
export const BG_COVER_SLIDESHOW_3 = `https://ik.imagekit.io/farahoktarina/yerryyohanaririassa/option%20photo%202.jpg?updatedAt=1705981145046`;
export const BG_COVER_SLIDESHOW_4 = `https://ik.imagekit.io/farahoktarina/yerryyohanaririassa/option%20photo%203.jpg?updatedAt=1705981144992`;
export const BG_COVER_SLIDESHOW_5 = `https://ik.imagekit.io/farahoktarina/yerryyohanaririassa/option%20photo%204.jpg?updatedAt=1705982795896`;

export const IMG_BRIDE_GROOM = `https://ik.imagekit.io/farahoktarina/yerryyohanaririassa/FOTO%20BRIDE%20AND%20GROOM.jpg?updatedAt=1705981145043`;
export const IMG_LOVE_STORY = `https://ik.imagekit.io/farahoktarina/yerryyohanaririassa/FOTO%20LOVE%20STORY%20PORTRAIT.jpg?updatedAt=1705981148700`;

export const BG_RSVP = `https://ik.imagekit.io/farahoktarina/yerryyohanaririassa/FOTO%20BACKGROUND%20RSVP.jpg?updatedAt=1705981145044`;
export const BG_GIFTCARD = `https://ik.imagekit.io/farahoktarina/yerryyohanaririassa/FOTO%20BACKGROUND%20WEDDING%20GIFT.jpg?updatedAt=1705999454180`;
export const BG_IG_FILTER = `https://ik.imagekit.io/farahoktarina/yerryyohanaririassa/FOTO%20BACKGROUND%20WEEDING%20FILTER.jpg?updatedAt=1705981145072`;
import bgPrewedding from '@/images/compassion/4.jpg'
export const BG_LIVESTREAM = bgPrewedding;

export const IMG_FOOTER_1 = `https://ik.imagekit.io/farahoktarina/yerryyohanaririassa/FOTO%20FOOTER.jpg?updatedAt=1705982445639`;
export const IMG_FOOTER_2 = `https://ik.imagekit.io/farahoktarina/yerryyohanaririassa/FOTO%20FOOTER(1).jpg?updatedAt=1705981145549`;
export const IMG_FOOTER_3 = `https://ik.imagekit.io/farahoktarina/yerryyohanaririassa/FOTO%20FOOTER(2).jpg?updatedAt=1705981144662`;

export const IMG_LOGO = `https://user-images.githubusercontent.com/10141928/162121855-c5b68987-ca66-415e-b53a-42ac77189f29.png`;

export const IMG_MAN = `https://user-images.githubusercontent.com/10141928/160724867-90f36427-a2f3-4a21-908d-ee3f77422aff.jpg`;
export const IMG_GIRL = `https://user-images.githubusercontent.com/10141928/160724874-4a83d701-174a-4c69-8839-69e4521db5fa.jpg`;
export const IMG_QR_COVER = `https://ik.imagekit.io/drpq5xrph/Template%20Tiffany%20&%20Jared/7.%20Wedding%20Filter.jpg?updatedAt=1695372281892`;

export const IMG_PHOTO_1 =
  'https://ik.imagekit.io/farahoktarina/yerryyohanaririassa/FOTO%20GALERI.jpg?updatedAt=1705981148273';
export const IMG_PHOTO_2 =
  'https://ik.imagekit.io/masansgaming/Template_Hendry_Clarissa/2_NUamww_9b.jpg?updatedAt=1679724181595';
export const IMG_PHOTO_CONTENT =
  'https://user-images.githubusercontent.com/10141928/162121692-3e7a781f-6b2c-4cc4-be9e-004df53eb69d.jpg';

export const IMG_AYAT =
  'https://user-images.githubusercontent.com/10141928/160853754-d5b769cd-701f-4fe1-ba72-e493bee8a122.jpg';

export const IMG_SLIDE_1 = `https://ik.imagekit.io/farahoktarina/yerryyohanaririassa/FOTO%20GALERI.jpg?updatedAt=1705981148273`;
export const IMG_SLIDE_2 = `https://ik.imagekit.io/invitatoid/template-rainajanssen/slide_2_11_dNMMPw.jpg?updatedAt=1638541152275`;
export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/103095433/241068547-20f04f29-1531-4bc2-a5e5-c7e3ce1c2805.png`,
  en: `https://user-images.githubusercontent.com/103095433/241068561-c7d17d12-c32f-44a1-834a-af73e655b0b6.jpg`,
};

export const LOGO_INVITATO = `https://ik.imagekit.io/invitatoid/global-assets/invitato_hs3nyqsuwy_.png?updatedAt=1638541184695`;
export const SONG_CDN_URL = `https://res.cloudinary.com/invitato/video/upload/v1640358887/template-song/LeeHi_-_ONLY_Lyrics_nzuosa.mp4`;
